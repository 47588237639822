import React from "react"
import Navigation from "./Navigation"

export default ({ open, toggle }) => {
  const statusClass = open ? "open" : null
  return (
    <div className={`slideout-wrapper ${statusClass}`}>
      <div className="slideout-outside" onClick={() => toggle(!open)} />
      <div className="slideout-inside">
        <div className="slideout-nav">
          <Navigation />
        </div>
      </div>
    </div>
  )
}
