import React from "react"
import { Link } from "gatsby"

const Navigation = () => {
  return (
    <ul>
      <li>
        <Link to="/">Home</Link>
      </li>
      <li>
        <Link to="/events">Events</Link>
      </li>
      <li className="hasMenu">
        <Link to="/about">About Us</Link>
        <ul className="menu">
          <li className="menuItem">
            <Link to="/our-inspiration">Our Inspiration</Link>
          </li>
          <li className="menuItem">
            <Link to="/board-of-directors">Board of Directors</Link>
          </li>
          <li className="menuItem">
            <Link to="/supporters">Supporters</Link>
          </li>
          <li className="menuItem">
            <Link to="/donate">Donate</Link>
          </li>
          <li className="menuItem">
            <Link to="/contact">Contact</Link>
          </li>
        </ul>
      </li>
      <li className="hasMenu">
        <Link to="/scholarships">Scholarships</Link>
        <ul className="menu">
          <li className="menuItem">
            <Link to="/scholarships/2018-recipients">2018 Recipients</Link>
          </li>
          <li className="menuItem">
            <Link to="/scholarships/2017-recipients">2017 Recipients</Link>
          </li>
          <li className="menuItem">
            <Link to="/scholarships/2016-recipients">2016 Recipients</Link>
          </li>
          <li className="menuItem">
            <Link to="/scholarships/2015-recipients">2015 Recipients</Link>
          </li>
          <li className="menuItem">
            <Link to="/scholarships/2014-recipients">2014 Recipients</Link>
          </li>
        </ul>
      </li>
      <li>
        <Link to="/community-helping-hands">Helping Hands</Link>
      </li>
      <li>
        <Link to="/local-food-pantries">Local Food Pantries</Link>
      </li>
    </ul>
  )
}

export default Navigation
