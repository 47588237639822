/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Header from "./header"
import Footer from "./Footer"
import "../sass/app.scss"

const HomeFooter = () => (
  <section className="elevator-section section section-text-right bg-white">
    <div className="container flex">
      <div className="flex-column-half">
        <img className="w-75 block lazy" src="/img/logo-tall.svg" alt="" />
      </div>
      <div className="flex-column-half">
        <p>
          In 2009, we began by raising funds to support local food pantries in
          Leyden Township. After our 2nd Annual Pre-Holiday Celebration & Food
          Drive, former Leyden H.S. teacher, Dominic Manola reached out to us
          asking if we would organize a fundraiser for Lucia Ramirez. At the
          time, Lucia was a senior at East Leyden H.S. fighting a serious
          medical condition (today she continues the fight).
        </p>
        <a className="button" href="/contact">
          Get Involved
        </a>
      </div>
    </div>
  </section>
)

const Layout = ({ children, location }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          <Helmet>
            <link rel="stylesheet" href="https://use.typekit.net/oyk8kqo.css" />
          </Helmet>
          <Header siteTitle={data.site.siteMetadata.title} />
          <main>{children}</main>
          {location.pathname === "/" ? <HomeFooter /> : null}
          <Footer />
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
