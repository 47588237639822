import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Navigation from "./Navigation"
import Slideout from "./Slideout"

const Header = ({ siteTitle }) => {
  const [slideoutOpen, setSlideoutOpen] = React.useState(false)
  return (
    <div>
      <header className="appHeader">
        <div className="container flex align-center">
          <div>
            <Link to="/" title="Home">
              <img
                src="https://theunityincommunity.org/img/logo.png"
                alt="The Unity in Community Logo"
                srcset="https://theunityincommunity.org/img/logo@2x.png 2x"
              />
            </Link>
          </div>
          <div className="flex-grow">
            <nav className="appNav">
              <div className="appNav-pre">
                <Link to="/supporters" className="supporters-link">
                  <svg
                    aria-hidden="true"
                    data-prefix="fas"
                    data-icon="heart"
                    class="svg-inline--fa fa-heart fa-w-16"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="currentColor"
                      d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
                    />
                  </svg>
                  We love our supporters!
                </Link>
                <Link className="button-green" to="/donate">
                  <img
                    src="https://theunityincommunity.org/img/icon/gift.svg"
                    alt="Donate Icon"
                  />
                  Donate
                </Link>
              </div>
              <div
                className="appNav-hamburger"
                // :className="{'fixed': slideoutOpen}"
                onClick={() => setSlideoutOpen(!slideoutOpen)}
              >
                <img
                  src="https://theunityincommunity.org/img/hamburger.svg"
                  alt="Toggle Navigation"
                />
              </div>
              <div className="appNav-main">
                {/* Navigation */}
                <Navigation />
              </div>
            </nav>
          </div>
        </div>
        <Slideout open={slideoutOpen} toggle={setSlideoutOpen} />
      </header>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
