import React from "react"
import { Link } from 'gatsby'

const Footer = () => {
  return (
    <>
      {/* // @yield('footer-before') */}
      <section className="donate-section section section-text-right bg-blue text-white">
            <div className="container flex">
                <div className="flex-column-half donate-section-image">
                    <img className="fixed-to-bottom" src="/img/PayPalScreen.png" alt="" srcset="/img/PayPalScreen@2x.png 2x"/>
                </div>
                <div className="flex-column-half">
                    <h3>Donate Now</h3>
                    <p>
                        If you would like to donate to The Unity in Community Foundation general funds, which will be divided between our programs as needed, please click the donate link below. We appreciate your support!
                    </p>
                    <Link className="button-green" to="/donate">
                        <img src="/img/icon/gift.svg" alt="Donate Icon" />
                        Donate
                    </Link>
                </div>
            </div>
        </section>
        {/* @yield('footer-between') */}
        <footer className="appFooter">
            <div className="container">
                <p>
                    The Unity in Community Foundation, does not endorse any particular candidate for public office or corporate sponsor, and the foundation has no agency relationship with organizations to which it may be linked.
                </p>
                <div className="footer-copyright">
                    © { (new Date()).getFullYear() } The Unity In Community Foundation  |  <a href="https://antlur.co" rel="noopener noreferrer" target="_blank">Site Designed by Antlur</a>
                </div>
            </div>
        </footer>
    </>
  )
}

export default Footer
